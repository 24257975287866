if ($(window).width() > 960) {

	if ($('.wrapper').hasClass("wrapper--index")){
        $(".js-header").addClass("is-dark");
	}
	
	$(".js-slider-control a").on("click" , function() {
		$(".js-slider-control li").removeClass("active");
		$(this).parents("li").addClass("active");
	})

	initialize(false);


	function initialize(hasScrollBar){


		new fullpage('#myContainer', {
			anchors: ['firstPage', 'secondPage', '3rdPage', '4thpage' , '5thpage' , '6thpage', '7thpage', '8thpage', '9thpage', '10thpage', '11thpage', 'last'],
			menu: '#menu',
			slidesNavigation: true,
			parallax: true,
			parallaxKey: 'YWx2YXJvdHJpZ28uY29tXzlNZGNHRnlZV3hzWVhnPTFyRQ==',
			parallaxOptions: {
				type: 'reveal',
				percentage: 62,
				property: 'translate'
			},
			scrollingSpeed: 1000,
			autoScrolling: true,
			scrollBar: hasScrollBar,
			fitToSection:false,
			showActiveTooltip: true,
			onLeave: function(index, nextIndex, direction) {
				$(".js-header-base").addClass("is-hidden");
				$(".js-header-fixed").addClass("is-active");
				console.log("onLeave", index.index , direction , nextIndex.index ,$(index));
				if (direction == "up"  && nextIndex.index == 0) {
					$(".js-header-base").removeClass("is-hidden");
					$(".js-header-fixed").removeClass("is-active");
				}

				if (direction == "down") {
					$(index.item).find(".dark").addClass("is-active");
					$(".js-page-control").removeClass("is-active");
					$(".js-page-control:eq("+(index.index+1)+")").addClass("is-active");
					console.log("index - " , index.index)
				}

				if (direction == "up") {
					$(".dark").removeClass("is-active");
					$(".js-page-control").removeClass("is-active");
					$(".js-page-control:eq("+(index.index-1)+")").addClass("is-active");
				}
				
			
			

				if (direction == "down"  && nextIndex.index > 4 && nextIndex.index < 10) {
					$(".js-slider-control").addClass("is-active");
					$(".js-slider-control-title").addClass("is-active");
				}

				if (direction == "up"  && index.index > 4 && index.index < 11) {
					$(".js-slider-control").addClass("is-active");
					$(".js-slider-control-title").addClass("is-active");
				}

				if (direction == "up"  && index.index < 4) {
					$(".js-slider-control").removeClass("is-active");
					$(".js-slider-control-title").removeClass("is-active");
				}

				if (nextIndex.index == 10) {
					$(".js-slider-control").removeClass("is-active");
					$(".js-slider-control-title").removeClass("is-active");
				}

				$(".js-slider-control-item").removeClass("active");
				$(".js-slider-control-item[data-item="+nextIndex.index+"]").addClass("active");

				/*if (nextIndex.index == 6) {

				}

				if (nextIndex.index == 7) {
					
				}

				if (nextIndex.index == 8) {
					
				}

				if (nextIndex.index == 9) {
					
				}*/

				
			},
			afterLoad: function(anchor, index, direction){
				if (direction == "up") {
					$(index.item).find(".dark").removeClass("is-active");
				}

				if (direction == "down") {
					let colorPanel = $(index.item).data("color");
					console.log("anchor = " , index.item);
					console.log("anchor = " , index , " index = " ,index, colorPanel );

					if (colorPanel == "white") {
						$(".js-header").removeClass("is-dark").addClass("is-white")
					} else {
						$(".js-header").addClass("is-dark").removeClass("is-white")
					}
				}
			

			/*	if (colorPanel == "white") {
					$(".js-header").removeClass("is-dark").addClass("is-white")
				} else {
					$(".js-header").addClass("is-dark").removeClass("is-white")
				}*/
			}
		});
	}
}